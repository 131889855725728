import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './auth-guard/admin.guard';

const routes: Routes = [
	{ path: '', loadChildren: () => import('./website/website.module').then((m) => m.WebsiteModule), },
	{ path: 'customer', loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule), },
	{ path: 'admin', loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule), canActivate :[AdminGuard] },
	{ path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule) },
	{ path: '**', redirectTo: '/not-found' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
	exports: [RouterModule]
})
export class AppRoutingModule { }

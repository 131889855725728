import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AdminInterceptor implements HttpInterceptor {
	token:any = localStorage.getItem('adminLoginData')
  constructor() {
	console.log('000000000000000000000000000000000000');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	  console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
	  
	request = request.clone({
		setHeaders: {
			// 'Content-Type': 'application/json',
			'Authorization': 'Bearer' +this.token.token,
		}
	});
    return next.handle(request);
  }
}

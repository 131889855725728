import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from './shared-services/shared.service'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AdminInterceptor } from "./admin/admin-shared/admin.interceptor";
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
	HttpClientModule,
	BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
	FullCalendarModule
  ],
  providers: [SharedService, AdminInterceptor],
  bootstrap: [AppComponent]
})
export class AppModule { }

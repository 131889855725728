import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
	ToastrModule
  ],
})
export class SharedModule {}

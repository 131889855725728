import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class SharedService {
    scrollTO(){
          var elmnt:any = document.getElementById("spaandsalonbody");           
            elmnt.scrollIntoView();
      }
}

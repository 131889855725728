import { Component } from '@angular/core';
import { SharedService } from "./shared-services/shared.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'lighthouse-spa';
  constructor(private SharedService:SharedService){
    
  }
  changeOfRoutes(evt:any){    
    // window.scroll(0,0)
    this.SharedService.scrollTO()
  }

}
